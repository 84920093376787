import BarclaysPlugin from "../plugins/BarclaysPlugin.mjs"
import KlarnaPlugin from "../plugins/KlarnaPlugin.mjs"
import ZiniaAmazon from "../plugins/ZiniaAmazon.mjs"
import { createBlob } from "../util/FileUtils.mjs"

console.log("hello world")
const plugins = [new KlarnaPlugin(), new BarclaysPlugin(), new ZiniaAmazon()]

// TODO maybe turn into initial available plugins:
plugins.forEach(plugin => console.log(plugin.info()))

function appendResultText(text) {
    const textOut = document.getElementById('outputTxt');
    textOut.innerText += "\n";
    textOut.innerText += text;
}

const textIn = document.getElementById('inputTxt');
const textOut = document.getElementById('outputTxt');
const pluginChoice = document.getElementById('pluginChoice');

var chosenPlugin = null;
var transactions = [];
var qifContent = "";
var csvContent = "";


textIn.addEventListener('input', function (event) {
    console.log("input event triggered")
    const inputValue = event.target.value;
    qifContent = "";
    csvContent = "";

    try {
        const jsonData = JSON.parse(inputValue);
        console.log("attaining plugins")
        plugins.forEach(plugin => console.log(plugin.info()))
        const applicablePlugins = plugins.filter((plugin) => plugin.isApplicable(jsonData))

        console.log(applicablePlugins)

        // Only wanna continue with one from here, TODO create plugin-SELECT
        if(applicablePlugins.length < 1) {
            throw new Error("No plugin able to parse given json")
        }
        chosenPlugin = applicablePlugins[0];
        console.log(`Selected plugin ${chosenPlugin.info()}`)
        pluginChoice.innerText = `Selected plugin: ${chosenPlugin.info()}`
        transactions = chosenPlugin.parseTransactions(jsonData);

        //let transactions = parseTransactions(inputValue);
        qifContent = chosenPlugin.toQif(transactions)
        csvContent = chosenPlugin.toCsv(transactions)

        textOut.innerText = qifContent;
    } catch (error) {
        console.error("An error occurred:", error.message);
        textOut.innerText = error.message;
    }
});

function createDownloadButton(elementId, fnContent, fnFileName) {
    const targetBtn = document.getElementById(elementId);
    targetBtn.addEventListener('click', async function () {
        if(!chosenPlugin || !fnContent()) {
            alert("Content unavailable")
            return;
        }
        const blob = createBlob(fnContent())
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);        
        link.download = `${chosenPlugin.info()}_${fnFileName()}`.toLowerCase().replace(' ', '_')
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    });
}
createDownloadButton('downloadQif', () => qifContent, () => "transactions.qif")
createDownloadButton('downloadCsv', () => csvContent, () => "transactions.csv")



textIn.disabled = false;
textIn.placeholder = "Paste input JSON here"


// Fire initial event
const event = new Event('input', {
    bubbles: true,
    cancelable: true
});
textIn.dispatchEvent(event);

